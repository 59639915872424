import { ICard } from '@/types/main'
import { fetchGraphQL } from '@/utils/api-helpers'

import { IGQLPageVariables, IPageGQLResults } from './api-graphql'

export type ICardGQLResults = IPageGQLResults<ICard[]>
export type ICardDualStateGQLResults = {
  valid: ICardGQLResults
  expired: ICardGQLResults
}

export const getCardValidResults = async (
  variables: IGQLPageVariables = {}
): Promise<ICardGQLResults> => {
  const query = `
    query CardValidResults (
      $itemsPerPage: Int = 10
    ) {
      cards: collectionPageQueryValidCards (
        itemsPerPage: $itemsPerPage
      ) {
        collection {
          ${MINIMAL_CARD_FIELDS}
          company {
            name
          }
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    cards: ICardGQLResults
  }>(query, { ...variables, authentication: 'required' })

  return graphqlResult.data.cards
}

export interface ICardValidLessonResultsVariables extends IGQLPageVariables {
  lessonUuid: string
}
export const getCardValidLessonResults = async (
  variables: ICardValidLessonResultsVariables
): Promise<ICardGQLResults> => {
  const query = `
    query CardValidLessonResults (
      $lessonUuid: String!
      $itemsPerPage: Int = 10
    ) {
      cards: collectionPageQueryValidLessonCards (
        itemsPerPage: $itemsPerPage
        lessonUuid: $lessonUuid
      ) {
        collection {
          ${MINIMAL_CARD_FIELDS}
          company {
            name
          }
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    cards: ICardGQLResults
  }>(query, { ...variables, authentication: 'required' })

  return graphqlResult.data.cards
}

export const getCardRegularDualStateResults = async (
  variables: IGQLPageVariables = {}
): Promise<ICardDualStateGQLResults> => {
  const query = `
    query CardDualStateResults (
      $itemsPerPage: Int = 10
    ) {
      valid: collectionPageQueryValidRegularCards (
        itemsPerPage: $itemsPerPage
      ) {
        collection {
          ${MINIMAL_CARD_FIELDS}
          company {
            name
          }
        }
      }

      expired: collectionPageQueryExpiredRegularCards (
        itemsPerPage: $itemsPerPage
      ) {
        collection {
          ${MINIMAL_CARD_FIELDS}
          company {
            name
          }
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    valid: ICardGQLResults
    expired: ICardGQLResults
  }>(query, { ...variables, authentication: 'required' })

  return graphqlResult.data
}

export const getCardSubscriptionDualStateResults = async (
  variables: IGQLPageVariables = {}
): Promise<ICardDualStateGQLResults> => {
  const query = `
    query CardDualStateResults (
      $itemsPerPage: Int = 5
    ) {
      valid: collectionPageQueryValidSubscriptionCards (
        itemsPerPage: $itemsPerPage
      ) {
        collection {
          id
          _id
          name
          type
          prepaid
          computedSubscription
        }
      }

      expired: collectionPageQueryExpiredSubscriptionCards (
        itemsPerPage: $itemsPerPage
        order: [{expireAt: "DESC"}]
      ) {
        collection {
          id
          _id
          name
          type
          prepaid
          computedSubscription
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    valid: ICardGQLResults
    expired: ICardGQLResults
  }>(query, { ...variables, authentication: 'required' })

  return graphqlResult.data
}

const MINIMAL_CARD_FIELDS = `
  id
  _id
  name
  type
  capacity
  remaining
  createdAt
  expireAt
  prepaid
  unlimited
`
