import React from 'react'
import { theme } from 'theme'

import { ISvgProps } from '@/types/main'

export const PlaceIcon: React.FC<ISvgProps> = ({ className }) => (
  <svg
    width="48"
    height="60"
    viewBox="0 0 48 60"
    fill={theme.color.black}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M23.9999 34.8825C21.8941 34.887 19.8342 34.2666 18.0811 33.0999C16.328 31.9332 14.9604 30.2726 14.1515 28.3284C13.3425 26.3841 13.1286 24.2435 13.5367 22.1776C13.9448 20.1117 14.9566 18.2133 16.4441 16.7226C17.9315 15.232 19.8278 14.2162 21.8929 13.8037C23.9579 13.3912 26.0989 13.6007 28.0449 14.4055C29.9909 15.2104 31.6543 16.5744 32.8247 18.325C33.9952 20.0757 34.6199 22.1342 34.6199 24.24C34.6199 27.0587 33.5017 29.7623 31.5107 31.7575C29.5198 33.7527 26.8186 34.8766 23.9999 34.8825ZM23.9999 18.12C22.7831 18.1156 21.5924 18.4726 20.5789 19.1459C19.5653 19.8191 18.7745 20.7782 18.3068 21.9015C17.8391 23.0248 17.7156 24.2617 17.9519 25.4553C18.1882 26.6489 18.7736 27.7455 19.634 28.6059C20.4944 29.4663 21.591 30.0518 22.7846 30.2881C23.9782 30.5244 25.2151 30.4008 26.3384 29.9331C27.4618 29.4654 28.4208 28.6746 29.0941 27.6611C29.7673 26.6475 30.1243 25.4568 30.1199 24.24C30.114 22.6187 29.4673 21.0655 28.3208 19.9191C27.1744 18.7726 25.6212 18.126 23.9999 18.12Z" />
    <path d="M24.0004 59.2501C21.9107 59.2267 19.9049 58.4243 18.3754 57.0001C11.8279 51.1501 0.780427 39.1576 0.487927 24.7351C0.304153 18.986 2.26291 13.3736 5.98403 8.98721C9.70514 4.60087 14.9232 1.75346 20.6254 0.997629C23.9601 0.513483 27.3599 0.752456 30.5941 1.69833C33.8283 2.6442 36.8211 4.27483 39.3694 6.47952C41.9178 8.68422 43.9619 11.4114 45.3631 14.4759C46.7644 17.5404 47.4899 20.8705 47.4904 24.2401C47.4904 38.9326 36.2404 51.2401 29.4904 57.1576C27.9777 58.5028 26.0247 59.2472 24.0004 59.2501ZM21.2329 5.45263C16.6161 6.07568 12.3967 8.39584 9.39755 11.9607C6.39837 15.5256 4.83435 20.0797 5.01043 24.7351C5.25793 37.4476 15.3829 48.3601 21.3904 53.7376C22.0972 54.3748 23.0151 54.7275 23.9667 54.7275C24.9183 54.7275 25.8361 54.3748 26.5429 53.7376C32.6854 48.2701 42.9904 37.1326 42.9904 24.1951C42.9869 21.4791 42.4029 18.7953 41.2776 16.3234C40.1522 13.8515 38.5115 11.6487 36.4654 9.86263C34.4066 8.04959 31.977 6.70764 29.3461 5.93041C26.7152 5.15319 23.9464 4.95942 21.2329 5.36263V5.45263Z" />
  </svg>
)
