import React from 'react'
import ReactModal from 'react-modal'
import styled from 'styled-components'

import { CloseIcon } from '@/components/_svg/icons/CloseIcon'
import { DEVICE_WIDTH } from '@/utils/constants'

ReactModal.setAppElement('body')

interface IProps extends ReactModal.Props {
  title?: string
  modalTitle?: string
}

export const Modal: React.FC<React.PropsWithChildren<IProps>> = ({
  title,
  modalTitle,
  children,
  ...props
}) => (
  <ReactModal
    className="_"
    overlayClassName="_"
    contentElement={(props, children) => (
      <ModalContent {...props}>{children}</ModalContent>
    )}
    overlayElement={(props, contentEl) => (
      <ModalOverlay {...props}>{contentEl}</ModalOverlay>
    )}
    {...props}
  >
    {props.onRequestClose && (
      <CloseWrapper>
        <button onClick={props.onRequestClose}>
          <StyledCloseIcon />
        </button>
      </CloseWrapper>
    )}

    {modalTitle && <ModalTitle>{modalTitle}</ModalTitle>}
    {title && <Title>{title}</Title>}

    {children}
  </ReactModal>
)

const ModalContent = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.spacing.m};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 95vh;
  max-width: 99%;
  min-width: 99%;
  overflow-y: auto;
  padding: ${({ theme }) => theme.spacing.s};

  &::-webkit-scrollbar {
    width: ${({ theme }) => theme.spacing.xxs};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.color.black};
    border-radius: ${({ theme }) => theme.spacing.xxs};
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.color.greyLighter};
    border-radius: ${({ theme }) => theme.spacing.xxs};
  }

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    padding: ${({ theme }) => theme.spacing.m};
    max-width: 60%;
    min-width: 60%;
  }
`
const ModalOverlay = styled.div`
  align-items: center;
  background: rgb(0 0 0 / 75%);
  display: flex;
  inset: 0;
  justify-content: center;
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.headerDesktop + 1};
`
const ModalTitle = styled.div`
  ${({ theme }) => theme.typo.overline}

  margin-bottom: ${({ theme }) => theme.spacing.m};
`
const Title = styled.div`
  ${({ theme }) => theme.typo.h3}

  margin-bottom: ${({ theme }) => theme.spacing.s};
`
const CloseWrapper = styled.div`
  text-align: right;
`
const StyledCloseIcon = styled(CloseIcon)`
  fill: ${({ theme }) => theme.color.terracota};
  height: ${({ theme }) => theme.spacing.s};
  width: ${({ theme }) => theme.spacing.s};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    margin-bottom: ${({ theme }) => theme.spacing.m};
  }
`
export const ButtonListWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xs};
  margin-top: ${({ theme }) => theme.spacing.s};

  button {
    width: 100%;

    @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
      width: fit-content;
    }
  }

  a {
    width: fit-content;
  }

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing.s};
    justify-content: center;
  }
`
