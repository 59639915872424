import React from 'react'
import { defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl'
import styled from 'styled-components'

import { ILessonVisit } from '@/types/main'

interface IProps {
  targetVisit: ILessonVisit
}

export const CancellationPolicy: React.FC<IProps> = ({ targetVisit }) => (
  <FormattedMessage
    tagName={Text}
    {...(cancellationMessages[targetVisit.cancellationPolicy.msg] ??
      cancellationMessages.generic)}
    values={{
      currentlyRecredit: targetVisit.cancellationPolicy.currently_recredit ?? null,
    }}
  />
)

const Text = styled.p`
  margin-bottom: ${({ theme }) => theme.spacing.m};
`

const cancellationMessages = defineMessages<
  ILessonVisit['cancellationPolicy']['msg'] | 'generic',
  MessageDescriptor
>({
  generic: {
    defaultMessage: `?`,
    description: 'CancellationPolicy: generic',
  },
  waitlist_alwaysRecredit: {
    defaultMessage: `
      L'annulation d'une réservation en liste d'attente n'engendre pas de perte de crédit.
    `,
    description: 'CancellationPolicy: waitlist_alwaysRecredit',
  },
  lessonSpecial_noCredit: {
    defaultMessage: `
      L'annulation de cette réservation n'engendre pas de perte de crédit.
    `,
    description: 'CancellationPolicy: lessonSpecial_noCredit',
  },
  lessonSpecial_neverRecredit: {
    defaultMessage: `
      L'annulation d'une réservation sur ce type de cours particulier engendre une perte crédit.
    `,
    description: 'CancellationPolicy: lessonSpecial_neverRecredit',
  },
  lessonOnlineWithSubscription_alwaysRecredit: {
    defaultMessage: `
      L'annulation d'une réservation de cours en ligne dans le cadre de votre abonnement n'engendre
      pas de perte de crédit.
    `,
    description: 'CancellationPolicy: lessonOnlineWithSubscription_alwaysRecredit',
  },
  lateCanceledAdvantage_conditionRecredit_16hours: {
    defaultMessage: `
      L'annulation d'une réservation de cours dans le cadre de votre abonnement
      {currentlyRecredit, select,
        true {n'engendre pas de perte de crédit car l'annulation intervient plus de}
        other {engendre une perte de crédit car l'annulation intervient moins de}
      } 16h avant le début du cours.
    `,
    description: 'CancellationPolicy: lateCanceledAdvantage_conditionRecredit_16hours',
  },
  lateCanceledAdvantage_conditionRecredit_24hours: {
    defaultMessage: `
      L'annulation d'une réservation de cours dans le cadre de votre abonnement
      {currentlyRecredit, select,
        true {n'engendre pas de perte de crédit car l'annulation intervient plus de}
        other {engendre une perte de crédit car l'annulation intervient moins de}
      } 24h avant le début du cours.
    `,
    description: 'CancellationPolicy: lateCanceledAdvantage_conditionRecredit_24hours',
  },
  priceCancellation_alwaysRecredit: {
    defaultMessage: `
      L'annulation d'une réservation de cours dans le cadre du tarif spécial utilisé pour la
      réservation n'engendre pas de perte de crédit.
    `,
    description: 'CancellationPolicy: priceCancellation_alwaysRecredit',
  },
  priceCancellation_neverRecredit: {
    defaultMessage: `
      L'annulation d'une réservation de cours dans le cadre du tarif spécial utilisé pour la
      réservation engendre une perte de crédit.
    `,
    description: 'CancellationPolicy: priceCancellation_neverRecredit',
  },
  priceCancellation_conditionRecredit_1day: {
    defaultMessage: `
      L'annulation d'une réservation de cours dans le cadre du tarif spécial utilisé pour la réservation
      {currentlyRecredit, select,
        true {n'engendre pas de perte de crédit car l'annulation intervient plus de}
        other {engendre une perte de crédit car l'annulation intervient moins de}
      } 24h avant le début du cours.
    `,
    description: 'CancellationPolicy: priceCancellation_conditionRecredit_1day',
  },
  default_conditionRecredit_1day: {
    defaultMessage: `
      L'annulation d'une réservation de cours
      {currentlyRecredit, select,
        true {n'engendre pas de perte de crédit car l'annulation intervient plus de}
        other {engendre une perte de crédit car l'annulation intervient moins de}
      } 24h avant le début du cours.
    `,
    description: 'CancellationPolicy: default_conditionRecredit_1day',
  },
})
