import { HTTPError } from '@/utils/api-helpers'

export type AsyncLoaderState<T, U = HTTPError> = {
  asyncStatus: 'idle' | 'pending' | 'succeed' | 'failed'
  asyncData: T
  asyncError: U
}

export const asyncLoaderInitialState: AsyncLoaderState<undefined, undefined> = {
  asyncStatus: 'idle',
  asyncData: undefined,
  asyncError: undefined,
}

export type AsyncLoaderAction<T, U = HTTPError> =
  | { type: 'pending' }
  | { type: 'succeed'; data: T }
  | { type: 'failed'; error: U }
  | { type: 'reset' }

export const asyncLoaderReducer = <T, U>(
  state: AsyncLoaderState<T, U>,
  action: AsyncLoaderAction<T, U>
): AsyncLoaderState<T, U> => {
  switch (action.type) {
    case 'pending':
      return { ...state, asyncStatus: 'pending' }
    case 'succeed':
      return { ...state, asyncStatus: 'succeed', asyncData: action.data }
    case 'failed':
      return { ...state, asyncStatus: 'failed', asyncError: action.error }
    case 'reset':
      return asyncLoaderInitialState
    default:
      return state
  }
}
