import { KeyedMutator, SWRConfiguration } from 'swr'

import {
  getLessonVisitResult,
  ILessonVisitResultVariables,
} from '@/services/api-graphql-lesson'
import { ILessonVisit } from '@/types/main'
import { HTTPError } from '@/utils/api-helpers'

import { useSWRAuth } from './useSwr-auth'
import { useSWRCustom } from './useSwr-custom'

export const SWR_KEY__LESSON_VISIT_RESULT = 'lessonVisit_result'

interface IReturnType {
  lessonVisitLoading: boolean
  lessonVisit: ILessonVisit
  lessonVisitError: HTTPError
  lessonVisitValidating: boolean
  mutateLessonVisit: KeyedMutator<ILessonVisit>
}

export const useSWRLessonVisitResult = (
  gqlVariables: ILessonVisitResultVariables,
  swrConfig: SWRConfiguration = { revalidateOnFocus: false }
): IReturnType => {
  const { authLoading, payload } = useSWRAuth()
  const { data, error, mutate, isLoading, isValidating } = useSWRCustom<ILessonVisit>(
    [gqlVariables.visitId && SWR_KEY__LESSON_VISIT_RESULT, payload?.uuid],
    getLessonVisitResult,
    gqlVariables,
    swrConfig
  )

  return {
    lessonVisitLoading: authLoading || isLoading,
    lessonVisit: data,
    lessonVisitError: error,
    lessonVisitValidating: isValidating,
    mutateLessonVisit: mutate,
  }
}
