import React, { createContext, useContext, useState } from 'react'

import { ILessonDecorated, ILessonVisit } from '@/types/main'

export interface IBookLessonBook {
  lesson: ILessonDecorated
  action: 'book'
  guestEmail?: string
}
export interface IBookLessonUnbook {
  lesson: ILessonDecorated
  action: 'unbook'
  lessonVisit?: ILessonVisit
}
export type IBookLesson = IBookLessonBook | IBookLessonUnbook
interface ILessonBookingContext {
  bookLesson: IBookLesson | undefined
  setBookLesson: React.Dispatch<React.SetStateAction<IBookLesson>>
}

export const LessonBookingContext = createContext<ILessonBookingContext | undefined>(
  undefined
)

export const LessonBookingContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [bookLesson, setBookLesson] = useState<IBookLesson>()

  return (
    <LessonBookingContext.Provider value={{ bookLesson, setBookLesson }}>
      {children}
    </LessonBookingContext.Provider>
  )
}

export const useLessonBooking = (): ILessonBookingContext => {
  const context = useContext(LessonBookingContext)

  if (context === undefined) {
    throw new Error(
      'useLessonBooking must be called inside a subtree of LessonBookingProvider'
    )
  }

  return context
}
