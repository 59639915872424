import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { TreeIcon } from '@/components/_svg/icons/TreeIcon'

export const PlaceOutdoor: React.FC = () => (
  <Wrapper>
    <StyledTreeIcon />
    <FormattedMessage
      tagName={GreyText}
      defaultMessage="En extérieur"
      description="PlaceOutdoor: label"
    />
  </Wrapper>
)

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs};
`
const GreyText = styled.p`
  color: ${({ theme }) => theme.color.grey};
`
const StyledTreeIcon = styled(TreeIcon)`
  fill: ${({ theme }) => theme.color.grey};
  height: ${({ theme }) => theme.spacing.s};
  width: ${({ theme }) => theme.spacing.s};
`
