import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { Modal } from '@/components/_shared/Modal'
import { IBookLessonUnbook, useLessonBooking } from '@/contexts/lessonBooking'
import { useSWRAuth } from '@/hooks/swr/useSwr-auth'
import { useSWRMutationCardValidLessonResults } from '@/hooks/swr/useSwr-cardValidLessonResults'

import { CancelBookingQuestionSuccessable } from './CancelBookingQuestionSuccessable'

interface IProps {
  withMutateCardList?: boolean
}

export const CancelBookingModal: React.FC<IProps> = ({ withMutateCardList = false }) => {
  const { authenticated } = useSWRAuth()
  const { bookLesson, setBookLesson } = useLessonBooking()
  const { trigger: mutateCardList } = useSWRMutationCardValidLessonResults({
    lessonUuid: bookLesson?.lesson.uuid,
  })
  const [isModalOpen, setModalOpen] = useState(false)
  const intl = useIntl()

  useEffect(() => {
    setModalOpen(bookLesson?.action === 'unbook')
  }, [bookLesson])

  if (!authenticated) {
    return null
  }

  const handleCloseModal = (triggerMutates = false) => {
    setBookLesson(undefined)

    if (!triggerMutates) {
      return
    }

    bookLesson?.lesson.mutate()
    if (withMutateCardList) {
      mutateCardList()
    }
  }

  const { lessonVisit = null } = (bookLesson ?? {}) as IBookLessonUnbook

  return (
    <StyledModal
      onRequestClose={() => handleCloseModal()}
      shouldCloseOnOverlayClick={true}
      isOpen={isModalOpen}
      modalTitle={intl.formatMessage(
        {
          defaultMessage: `
            {isCancelingWaitingList, select, true {Liste d'attente} other {Réservation}}
          `,
          description: 'CancelBookingModal: modalTitle',
        },
        {
          isCancelingWaitingList: lessonVisit?.waitlist,
        }
      )}
    >
      {bookLesson?.action === 'unbook' && (
        <CancelBookingQuestionSuccessable
          bookLesson={bookLesson}
          onDoneFn={handleCloseModal}
        />
      )}
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  text-align: center;
`
