import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'

import { CustomButton } from '@/components/_custom/CustomButton'
import { ContainerError, ContainerLoading } from '@/components/_layout/ContainerMessage'
import { IBookLessonUnbook } from '@/contexts/lessonBooking'
import { useSWRLessonVisitResult } from '@/hooks/swr/useSwr-lessonVisitResult'
import { useAsync } from '@/hooks/use-async'
import { cancelVisit, ICancelVisitArgs } from '@/services/api-rest'
import { ILessonVisit } from '@/types/main'
import { DEVICE_WIDTH } from '@/utils/constants'

import { CancellationPolicy } from './misc/CancellationPolicy'

interface IProps {
  bookLesson: IBookLessonUnbook
  onDoneFn: (triggerMutates?: boolean) => void
}

export const CancelBookingQuestionSuccessable: React.FC<IProps> = ({
  bookLesson,
  onDoneFn,
}) => {
  const { lesson, lessonVisit } = bookLesson
  const [confirmCancellation, setConfirmCancellation] = useState(false)
  const {
    lessonVisitLoading,
    lessonVisit: fullLessonVisit,
    lessonVisitError,
  } = useSWRLessonVisitResult({
    visitId: +lessonVisit.id,
  })
  const intl = useIntl()

  if (lessonVisitLoading) {
    return (
      <ContainerLoading
        message={intl.formatMessage({
          defaultMessage: 'Chargement en cours...',
          description: 'CancelBookingQuestionSuccessable: loading',
        })}
      />
    )
  }

  if (lessonVisitError) {
    return <ContainerError />
  }

  if (confirmCancellation) {
    return <CancelBookingSuccess bookLesson={bookLesson} onDoneFn={onDoneFn} />
  }

  return (
    <>
      <FormattedMessage
        tagName={Title}
        defaultMessage={`
          Annuler {nbActiveBookings, plural, one {cette réservation} other {ma réservation 1/{nbActiveBookings}}} ?
        `}
        description="CancelBookingQuestionSuccessable: title"
        values={{
          nbActiveBookings: lesson.getAuthUserActiveBookings().length,
        }}
      />

      <CancelBookingQuestionMessage targetVisit={lessonVisit} />
      <CancellationPolicy targetVisit={fullLessonVisit} />

      <FlexWrapper>
        <StyledButton onClick={() => onDoneFn()} outlined>
          <FormattedMessage
            defaultMessage="Non, maintenir ma réservation"
            description="CancelBookingQuestionSuccessable: button cancel"
          />
        </StyledButton>
        <StyledButton onClick={() => setConfirmCancellation(true)}>
          <FormattedMessage
            defaultMessage="Oui, annuler ma réservation"
            description="CancelBookingQuestionSuccessable: button accept"
          />
        </StyledButton>
      </FlexWrapper>
    </>
  )
}

const CancelBookingQuestionMessage = ({
  targetVisit,
}: {
  targetVisit?: ILessonVisit
}) => {
  if (targetVisit.guestEmail) {
    return (
      <FormattedMessage
        tagName={Text}
        defaultMessage={`
            {isCancelingWaitingList, select,
              true {L'inscription sur liste d'attente}
              other {La participation au cours}
            } de {guestEmail} sera annulée
          `}
        description="CancelBookingQuestionMessage: target invite"
        values={{
          isCancelingWaitingList: targetVisit.waitlist,
          guestEmail: targetVisit.guestEmail,
        }}
      />
    )
  }

  return (
    <FormattedMessage
      tagName={Text}
      defaultMessage={`
          Votre {isCancelingWaitingList, select,
            true {inscription sur liste d'attente}
            other {participation au cours}
          } sera annulée
        `}
      description="CancelBookingQuestionMessage: target own"
      values={{
        isCancelingWaitingList: targetVisit.waitlist,
      }}
    />
  )
}

export const CancelBookingSuccess: React.FC<IProps> = ({
  bookLesson: { lessonVisit },
  onDoneFn,
}) => {
  const intl = useIntl()
  const asyncCancelVisit = useAsync((args: ICancelVisitArgs) => cancelVisit(args))

  useEffect(() => {
    asyncCancelVisit.executeAsync({
      visit: lessonVisit,
    })
  }, [])

  if (['idle', 'pending'].includes(asyncCancelVisit.asyncStatus)) {
    return (
      <ContainerLoading
        message={intl.formatMessage({
          defaultMessage: 'Annulation en cours...',
          description: 'CancelBookingSuccess: loading',
        })}
      />
    )
  }

  if (asyncCancelVisit.asyncStatus === 'failed') {
    return <ContainerError />
  }

  return (
    <>
      <FormattedMessage
        tagName={Title}
        defaultMessage="Réservation annulée"
        description="CancelBookingSuccess: title"
      />
      <FormattedMessage
        tagName={Text}
        defaultMessage="Vous recevrez un email de confirmation d'ici quelques minutes"
        description="CancelBookingSuccess: subtitle"
      />

      <FlexWrapper>
        <StyledButton onClick={() => onDoneFn(true)}>
          <FormattedMessage
            defaultMessage="Fermer"
            description="CancelBookingSuccess: button close"
          />
        </StyledButton>
      </FlexWrapper>
    </>
  )
}

const Text = styled.p`
  margin-bottom: ${({ theme }) => theme.spacing.m};
`
const FlexWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xs};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing.s};
    justify-content: center;
  }
`
const StyledButton = styled(CustomButton)`
  width: 100%;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    width: fit-content;
  }
`
const Title = styled.div`
  ${({ theme }) => theme.typo.h3}

  margin-bottom: ${({ theme }) => theme.spacing.s};
`
