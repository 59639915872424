import React from 'react'
import { FormattedTimeParts } from 'react-intl'

interface IProps {
  startTime: string
  duration: number
}

export const TimeAndDuration: React.FC<IProps> = ({ startTime, duration }) => (
  <FormattedTimeParts value={startTime}>
    {(parts) => (
      <p suppressHydrationWarning>
        {parts[0].value}h{parts[2].value}
        {duration && ` - ${duration / 60}min`}
      </p>
    )}
  </FormattedTimeParts>
)
