import { KeyedMutator, SWRConfiguration } from 'swr'
import { SWRMutationResponse } from 'swr/mutation'

import {
  getCardValidLessonResults,
  ICardGQLResults,
  ICardValidLessonResultsVariables,
} from '@/services/api-graphql-card'
import { HTTPError } from '@/utils/api-helpers'

import { useSWRAuth } from './useSwr-auth'
import { useSWRCustom, useSWRMutateCustom } from './useSwr-custom'

export const SWR_KEY__CARD_VALID_LESSON_RESULTS = 'cardValidLesson_results'

export interface IReturnType {
  cardListLoading: boolean
  cardList: ICardGQLResults
  cardListError: HTTPError
  mutateCardList: KeyedMutator<ICardGQLResults>
}

export const useSWRCardValidLessonResults = (
  gqlVariables: ICardValidLessonResultsVariables,
  swrConfig: SWRConfiguration = { revalidateOnFocus: false }
): IReturnType => {
  const { authenticated, payload } = useSWRAuth()
  const { data, error, mutate, isLoading } = useSWRCustom<ICardGQLResults>(
    [
      authenticated && gqlVariables.lessonUuid && SWR_KEY__CARD_VALID_LESSON_RESULTS,
      payload?.uuid,
    ],
    getCardValidLessonResults,
    gqlVariables,
    swrConfig
  )

  return {
    cardListLoading: isLoading,
    cardList: data,
    cardListError: error,
    mutateCardList: mutate,
  }
}

export const useSWRMutationCardValidLessonResults = (
  gqlVariables: ICardValidLessonResultsVariables,
  swrConfig: SWRConfiguration = { revalidateOnFocus: false }
): SWRMutationResponse<ICardGQLResults> => {
  const { authenticated, payload } = useSWRAuth()

  return useSWRMutateCustom<ICardGQLResults>(
    [
      authenticated && gqlVariables.lessonUuid && SWR_KEY__CARD_VALID_LESSON_RESULTS,
      payload?.uuid,
    ],
    getCardValidLessonResults,
    gqlVariables,
    swrConfig
  )
}
