import { useReducer } from 'react'

import {
  AsyncLoaderAction,
  asyncLoaderInitialState,
  asyncLoaderReducer,
  AsyncLoaderState,
} from '@/reducers/asyncLoader'
import { HTTPError } from '@/utils/api-helpers'

export interface IUseAsyncReturnType<Data, Args, Err>
  extends AsyncLoaderState<Data, Err> {
  executeAsync: (asyncFnArgs?: Args) => Promise<void>
  resetAsync: () => void
}

export const useAsync = <Data, Args = unknown, Err = HTTPError>(
  asyncFn: (asyncFnArgs?: Args) => Promise<Data>
): IUseAsyncReturnType<Data, Args, Err> => {
  const [state, dispatch] = useReducer<
    React.Reducer<AsyncLoaderState<Data, Err>, AsyncLoaderAction<Data, Err>>
  >(asyncLoaderReducer, asyncLoaderInitialState)

  const executeAsync = async (asyncFnArgs?: Args) => {
    dispatch({ type: 'pending' })
    try {
      const asyncData = await asyncFn(asyncFnArgs)
      dispatch({ type: 'succeed', data: asyncData })
    } catch (asyncError) {
      dispatch({ type: 'failed', error: asyncError })
    }
  }

  const resetAsync = () => {
    dispatch({ type: 'reset' })
  }

  return { ...state, executeAsync, resetAsync }
}
